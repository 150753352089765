
















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageNotFound',
  setup() {
    const { cdn } = useCDN();

    return {
      cdn
    };
  }
});
