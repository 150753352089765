


































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, onMounted, watch, computed, onBeforeUnmount } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useRoute } from '@/utils/router.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageJoinWorkspace',
  components: {
    AlertError,
    AlertSuccess
  },
  setup(_, { root }) {
    const { cdn } = useCDN();
    const { params } = useRoute();

    const busModule = usePodocoreModule('bus');
    const requestModule = usePodocoreModule('request');

    const doctorService = usePodocoreModuleService('doctor');
    const workspacesService = usePodocoreModuleService('workspaces');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const error = ref<Error | undefined>(undefined);

    const joinRequest = ref<any>(undefined);

    function setError(errorString: string) {
      error.value = new Error(errorString);
    }

    onMounted(() => {
      if (!params.value.token) return setError('Missing token');
      if (!params.value.workspaceCuid) return setError('Missing workspace cuid');
      if (!params.value.doctorCuid) return setError('Missing doctor cuid');
      if (!params.value.env) return setError('Missing env');

      if (doctor.value.cuid !== params.value.doctorCuid) return setError('Doctor identifier does not match');
      if (process.env.BLACKBURN__STAGE !== params.value.env) return setError('Environment does not match');

      if (error.value) return;

      joinRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/workspaces/${params.value.workspaceCuid}/members/join`,
        {
          axios: {
            method: 'POST',
            data: {
              token: params.value.token
            }
          }
        }
      );

      joinRequest.value.request();
    });

    const data = computed(() => {
      return joinRequest.value?.data;
    });

    const isSuccess = computed(() => data.value?.cuid === params.value.workspaceCuid);

    watch(
      isSuccess,
      (value) => {
        if (value) {
          localStorage.setItem('workspace', data.value.cuid as string);

          // Add delay to let SaaS middleware update the customer
          setTimeout(() => {
            workspacesService.send({
              type: 'FETCH',
              data: {
                workspaceCuid: data.value.cuid
              }
            } as any);
          }, 2000);
        }
      },
      {
        deep: true
      }
    );

    busModule.useEventSubscriber(busModule.events.workspaceChanged, () => {
      root.$router.push({ name: 'home', params: { joinedWorkspace: params.value.workspaceCuid as string } });
    });

    onBeforeUnmount(() => {
      sessionStorage.removeItem('join_workspace');
    });

    return {
      cdn,
      error,
      joinRequest,
      isSuccess
    };
  }
});
