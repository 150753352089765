











































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, onMounted, watch, computed, onBeforeUnmount } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useRoute } from '@/utils/router.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageJoinWorkspace',
  components: {
    AlertError,
    AlertSuccess
  },
  setup(_, { root }) {
    const { cdn } = useCDN();
    const { hash } = useRoute();

    const busModule = usePodocoreModule('bus');
    const requestModule = usePodocoreModule('request');

    const doctorService = usePodocoreModuleService('doctor');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const error = ref<Error | undefined>(undefined);

    const connectRequest = ref<any>(undefined);

    function setError(errorString: string) {
      error.value = new Error(errorString);
    }

    onMounted(() => {
      if (!hash.value) return setError('Missing hash');

      const m = hash.value.match(/id_token=(.*)&/);

      if (!m?.[1]) return setError('Missing id_token');

      if (error.value) return;

      connectRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/podomigration`, {
        axios: {
          method: 'POST',
          data: {
            token: m[1]
          }
        }
      });

      connectRequest.value.request();
    });

    const data = computed(() => {
      return connectRequest.value?.data;
    });

    const isSuccess = computed(() => data.value?.cuid === doctor.value.cuid);

    watch(
      isSuccess,
      (value, previousValue) => {
        if (value && !previousValue) {
          doctorService.send({
            type: 'CHECK'
          } as any);

          setTimeout(() => {
            root.$router.push({
              name: 'settings',
              hash: '#podomigration'
            });
          }, 2000);
        }
      },
      {
        deep: true
      }
    );

    return {
      cdn,
      error,
      connectRequest,
      isSuccess
    };
  }
});
